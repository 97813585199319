<template>
  <div class="pb-4">
    <b-col cols="12">
      <h2 class="text-dark fw-bold-700 mb-3 size24">
        Simpan ke Lokasi SKU
      </h2>
      <div v-for="(warehouse, index) in warehouses" :key="index">
        <div class="d-flex justify-content-between px-1">
          <h6 class="text-dark fw-bold-700">
            {{ warehouse.name }}
          </h6>
          <b-form-checkbox :id="`yes-${index}`" ref="warehouseId"
            :checked="checkedWarehouse && checkedWarehouse.includes(warehouse.uuid) == true ? `${warehouse.uuid}` : ''"
            :value="warehouse.uuid" class="custom-control-success" @change="isRadioChecked">
            Ya
          </b-form-checkbox>
        </div>
        <hr>
      </div>
      <small
        v-if="messages.warehouses_uuid && Array.isArray(messages.warehouses_uuid) && messages.warehouses_uuid.length > 0"
        class="text-error fw-bold-500 size12">{{ messages.warehouses_uuid.join(', ') }}</small>
    </b-col>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    arrChecked: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
  },
  data() {
    return {
      isLoading: false,
      Selected: '',
      warehouses: [],
      formPayloadCheck: this.$store.state.parentProduct.formPayload.warehouses_uuid,
      checkedWarehouse: [],
      editId: this.$store.state.parentProduct.editId,
    }
  },
  watch: {
    arrChecked(value) {
      this.checkedWarehouse = value
    },
  },
  created() {
    this.getData()
  },
  methods: {
    isRadioChecked() {
      // const that = this
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.$refs.warehouseId) {
        if (Object.hasOwnProperty.call(this.$refs.warehouseId, key)) {
          const element = this.$refs.warehouseId[key]
          if (element.isChecked === true) {
            this.formPayloadCheck.push(element.value)
          } else {
            this.removeA(this.formPayloadCheck, element.value)
          }
        }
      }
      const items = [...new Set(this.formPayloadCheck)]
      this.$emit('getWarehouseUuid', items)
      // for (let index = 0; index < this.warehouses.length; index++) {
      // }
    },
    removeA(arr) {
      // eslint-disable-next-line prefer-rest-params
      let what; const a = arguments; let L = a.length; let
        ax
      while (L > 1 && arr.length) {
        // eslint-disable-next-line no-plusplus
        what = a[--L]
        // eslint-disable-next-line no-cond-assign
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1)
        }
      }
      return arr
    },
    getData() {
      this.isLoading = true
      this.$store.dispatch('warehouse/getData', {
        params: '',
      }).then(result => {
        this.warehouses = result.data.data.data
        this.isLoading = false
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
.custom-control-success .custom-control-input:checked~.custom-control-label::before {
  border: 5px solid #45B6AB;
}
</style>
