<template>
  <div class="mb-5">
    <b-row class="mb-2">
      <b-col cols="12">
        <b-row class="justify-content-center">
          <b-col md="11" class="px-0">
            <h6 class="size24 text-dark fw-bold-700 mb-2">
              Konversi Produk Induk ke Pecahan
            </h6>
          </b-col>
          <b-col md="5" class="border px-0 border-rad-top-left-8 border-rad-bottom-left-8">
            <h6 class="text-dark fw-bold-700 size14 mb-0 text-center mt-1">
              Produk Induk
            </h6>
            <hr />
            <div class="p-2">
              <b-form-group class="mb-2">
                <label for="v-produk-induk">Pilih produk induk <span class="text-danger">*</span></label>
                <v-select v-model="formPayload.retail_parent_uuid" label="title" placeholder="Pilih Produk Induk"
                  :class="Array.isArray(messages.retail_parent_uuid)
                    ? 'error-validation'
                    : ''
                    " :options="optionProduct" :reduce="(option) => option.label" @search="onSearch" />
                <small v-if="
                  messages.retail_parent_uuid &&
                  Array.isArray(messages.retail_parent_uuid) &&
                  messages.retail_parent_uuid.length > 0
                " class="text-error fw-bold-500 size12">{{ messages.retail_parent_uuid.join(", ") }}</small>
              </b-form-group>
              <div class="description p-2 border">
                <div class="">
                  <div class="box__upload--photo border-0 mb-1" :style="`background-image: url(${items.image_url})`">
                    <!-- <b-img
                      v-if="items.image_url"
                      class="box__upload--photo"
                      :src="items.image_url"
                    /> -->
                  </div>
                  <div class="">
                    <h4 class="text-dark fw-bold-700 size16">
                      {{ (items.item && items.item.name) || "-" }}
                    </h4>
                    <h6 class="text-black-2 font-weight-500 mb-1">
                      {{ items.sku_code || "-" }}
                    </h6>
                    <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Satuan
                    </span>
                    <h6 class="size12 text-dark fw-bold-500">
                      {{ (items.uom && items.uom.name) || "-" }}
                    </h6>
                    <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Spesifikasi
                    </span>
                    <h6 class="size12 text-dark fw-bold-500">
                      {{ items.specification || "-" }}
                    </h6>
                    <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Nama Alias
                    </span>
                    <h6 class="size12 text-dark fw-bold-500">
                      {{ items.alias_name || "-" }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="1" class="align-self-center text-center d-none d-md-block">
            <feather-icon size="24" icon="ArrowRightCircleIcon" />
          </b-col>
          <b-col md="5" class="border px-0 border-rad-top-right-8 border-rad-bottom-right-8">
            <h6 class="text-dark fw-bold-700 size14 mb-0 text-center mt-1">
              Produk Eceran
            </h6>
            <hr />
            <div class="p-2">
              <div class="d-flex mb-2">
                <b-form-group class="w-50 mb-0 mr-2" label="Nilai konversi" label-for="v-konversi">
                  <b-form-input id="v-konversi" v-model="formPayload.retail_convertion" type="number"
                    placeholder="Nilai konversi" class="custom__input" />
                </b-form-group>
                <b-form-group class="w-50 mb-0">
                  <label for="v-satuan">Pilih Satuan <span class="text-danger">*</span></label>
                  <v-select v-model="formPayload.uom_uuid" placeholder="Pilih Satuan" label="title" :class="Array.isArray(messages.uom_uuid) ? 'error-validation' : ''
                    " :options="optionUom" :reduce="(option) => option.label" @search="onSearchUom" />
                  <small v-if="
                    messages.uom_uuid &&
                    Array.isArray(messages.uom_uuid) &&
                    messages.uom_uuid.length > 0
                  " class="text-error fw-bold-500 size12">{{ messages.uom_uuid.join(", ") }}</small>
                </b-form-group>
              </div>
              <div class="description p-2 border">
                <div class="">
                  <label v-if="photo_url == ''" class="mb-0 w-100" for="upload_photo">
                    <div class="box__upload--photo">
                      <b-img class="mb-1" :src="require('@/assets/images/icons/Icon-upload.svg')"
                        style="background-position: center; object-fit: cover" />
                      <h6 class="text-dark fw-bold-800 size14">
                        Upload Foto (Optional)
                      </h6>
                      <small class="text-dark text-darken-4 size10 fw-bold-400 text-center">Ukuran maksimal foto
                        256kb</small>
                      <input id="upload_photo" type="file" accept="image/*" style="opacity: 0; height: 0"
                        @change="changePhoto" />
                    </div>
                  </label>
                  <div v-else class="box__upload--photo" :style="`background-image: url(${photo_url})`"
                    @click="photo_url = ''">
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                  <div class="mt-1">
                    <h4 class="text-dark fw-bold-700 size16">
                      {{ (items.item && items.item.name) || "-" }}
                    </h4>
                    <!-- <h6 class="text-black-2 font-weight-500 mb-1">
                      {{ skuCode || '-' }}
                    </h6> -->
                    <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Satuan
                    </span>
                    <h6 class="size12 text-dark fw-bold-500">
                      {{ uom.name || "" }}
                    </h6>
                    <!-- <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Spesifikasi
                    </span>
                    <h6 class="size12 text-dark fw-bold-500">
                      <input
                        v-model="formPayload.specification"
                        type="text"
                        class="input__name"
                        placeholder="Isi spesifikasi"
                      >
                    </h6> -->
                    <span class="size12 text-dark text-darken-5 fw-bold-500">
                      Nama Alias <span class="text-danger">*</span>
                    </span>
                    <br />
                    <input v-model="formPayload.alias_name" type="text" class="input__name"
                      placeholder="Isi nama disini" />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </b-col>
          <b-col cols="12" md="11" class="border-top border-bottom mt-2">
            <div class="d-flex justify-content-end py-2">
              <h6 class="text-dark fw-bold-500 mb-0 size16">
                Jumlah produk pecahan / 1 produk induk =
              </h6>
              <h6 class="text-primary fw-bold-500 mb-0 size16 ml-1">
                {{ formPayload.retail_convertion || 0 }} {{ uom.name || "" }}
              </h6>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" md="5">
        <b-form-group>
          <b-form-checkbox v-if="$route.params.id" v-model="formPayload.show_on_low_stock" class="mb-1" id="low_stock"
            name="low_stock" value="1" unchecked-value="0">
            Tampilkan saat stok menipis
          </b-form-checkbox>
          <b-form-checkbox v-else v-model="isCreateLowStock" class="mb-1" id="low_stock" name="low_stock" value="1"
            unchecked-value="0" disabled>
            Tampilkan saat stok menipis
          </b-form-checkbox>
          <div v-if="
            (!$route.params.id && isCreateLowStock == 1) ||
            ($route.params.id && formPayload.show_on_low_stock == 1)
          ">
            <label class="mb-25">Qty Stok Minimum <span class="text-danger">*</span></label>
            <b-form-input v-model="formPayload.min_low_stock" type="number" class="custom__input w-100"
              placeholder="" />
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="5">
        <b-form-group>
          <b-form-checkbox v-model="formPayload.show_on_dead_stock" class="mb-1" id="dead_stock" name="dead_stock"
            value="1" unchecked-value="0">
            Tampilkan produk di laporan Deadstock
          </b-form-checkbox>
          <div v-if="formPayload.show_on_dead_stock == 1">
            <label class="mb-25">Periode <span class="text-danger">*</span></label>
            <b-form-input v-model="formPayload.min_dead_stock" type="number" class="custom__input w-100"
              placeholder="" />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <div v-if="$route.params.id">
      <b-form-group>
        <b-form-checkbox
          v-model="formPayload.show_on_low_stock"
          class="mb-1"
          id="low_stock"
          name="low_stock"
          value="1"
          unchecked-value="0"
        >
          Tampilkan saat stok menipis
        </b-form-checkbox>
        <div v-if="formPayload.show_on_low_stock == 1">
          <label class="mb-25">Qty Stok Minimum</label>
          <b-form-input
            v-model="formPayload.min_low_stock"
            type="number"
            class="custom__input w-25"
            placeholder=""
          />
        </div>
      </b-form-group>
    </div>
    <div v-else-if="!$route.params.id">
      <b-form-group>
        <b-form-checkbox
          v-model="isCreateLowStock"
          class="mb-1"
          id="low_stock"
          name="low_stock"
          value="1"
          unchecked-value="0"
          disabled
        >
          Tampilkan saat stok menipis
        </b-form-checkbox>
        <div v-if="isCreateLowStock == 1">
          <label class="mb-25">Qty Stok Minimum</label>
          <b-form-input
            v-model="formPayload.min_low_stock"
            type="number"
            class="custom__input w-25"
            placeholder=""
          />
        </div>
      </b-form-group>
    </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BImg,
    BAvatar,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    formData: {
      type: Object,
    },
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    imageUrl: {
      type: String,
    },
    result: {
      type: Object,
    },
  },
  computed: {
    skuCode() {
      return `${this.items.item?.name || ""} - ${this.items.brand?.name || ""
        } - ${this.formPayload.specification || ""} | ${this.uom.name || ""}`;
    },
  },
  data() {
    return {
      photo: "",
      photo_url: "",
      optionItems: [],
      optionBrands: [],
      optionProduct: [],
      optionUom: [],
      formPayload: {
        image: "",
        retail_parent_uuid: "",
        uom_uuid: "",
        retail_convertion: 0,
        alias_name: "",
        // sku_code: '',
        // specification: '',
        show_on_low_stock: 1,
        show_on_dead_stock: 0,
      },
      resultProduct: [],
      items: {},
      uom: {},
      imageKey: 1,
      selectedUom: [],
      isChecked: false,
      isCreateLowStock: 1,
    };
  },
  watch: {
    // skuCode(value) {
    //   this.formPayload.sku_code = value
    // },
    formData: {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        this.$store.commit("parentProduct/setRetail", value);
        // this.$emit('payloadProduct', value)
      },
      deep: true,
    },
    "formPayload.retail_parent_uuid": function (value) {
      this.getProductDetail(value);
    },
    "formPayload.uom_uuid": function (value) {
      this.getUomDetail(value);
    },
    imageUrl(value) {
      this.photo_url = value;
    },
    // selectedUom(value) {
    //   this.formPayload.uom_uuid = value.label
    // },
  },
  created() {
    this.getData();
    this.getUom();
  },
  methods: {
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionProduct = [];
          this.getData();
          loading(false);
        }, 500);
      }
    },
    search: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("parentProduct/getData", {
          uuid: "",
          params: {
            search: search,
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionProduct = [];
          response.data.data.data.forEach((item) => {
            vm.optionProduct.push({
              label: item.uuid,
              title: item.alias_name,
            });
          });
          loading(false);
        });
    }, 300),
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUom(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionUom = [];
          this.getUom();
          loading(false);
        }, 500);
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = [];
          response.data.data.data.forEach((item) => {
            vm.optionUom.push({
              label: item.uuid,
              title: item.name,
              name: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    async getUom() {
      this.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: {
            per_page: 15,
          },
        })
        .then((result) => {
          result.data.data.data.forEach((element) => {
            this.optionUom.push({
              label: element.uuid,
              title: element.name,
            });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getUomDetail(id) {
      this.$store
        .dispatch("uom/getData", {
          params: "",
          uuid: id,
        })
        .then((result) => {
          this.uom = result.data.data;
          this.$store.commit("parentProduct/setUom", result.data.data.name);
          setTimeout(() => {
            this.optionUom.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionUom, "label");
            this.optionUom = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getProductDetail(id) {
      this.$store
        .dispatch("parentProduct/getData", {
          params: "",
          uuid: id,
        })
        .then((result) => {
          this.items = result.data.data;
          setTimeout(() => {
            this.optionProduct.unshift({
              label: result.data.data.uuid,
              title: result.data.data.alias_name,
            });
            const option = this.getUniqueListBy(this.optionProduct, "label");
            this.optionProduct = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getData() {
      this.$store
        .dispatch("parentProduct/getData", {
          params: {
            per_page: 15,
          },
          uuid: "",
        })
        .then((result) => {
          // this.result = result.data.data.data
          result.data.data.data.forEach((element) => {
            this.optionProduct.push({
              label: element.uuid,
              title: element.alias_name,
            });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.image = event.target.files[0];
      this.showPhoto(event.target.files[0]);
    },
    showPhoto(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photo_url = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.description {
  border-radius: 8px;

  .text-black-2 {
    color: #000000;
  }

  .input__name {
    width: 100%;
    margin-top: 5px;
    border: 0;
    border-bottom: 1px solid #e4e5ec;
    padding-bottom: 10px;

    &::placeholder {
      color: #cccfd3;
      font-size: 12px;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

label {
  margin-bottom: 1rem;
}

.custom__input {
  color: #2b324f !important;
  font-weight: 600;
}

.vs__selected {
  color: #2b324f !important;
  font-weight: 600;
}

.input__sku {
  label {
    margin-bottom: 1rem;
    color: #294f98;
  }

  .custom__input {
    background: #eef2fa !important;
    border: 1px solid #89a7e0 !important;
    border-radius: 8px !important;
    color: #294f98 !important;
    font-weight: 700;
  }
}
</style>
